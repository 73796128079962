
import { defineComponent, ref, onMounted, computed } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRoute } from "vue-router";
import moment from "moment";
import Datepicker from "vue3-datepicker";
import router from "@/router";
import store from "@/store";
import {
  addMaterialStock,
  getMaterialStockById,
  materialStockObjClone,
  getMaterialStockCountById,
} from "@/core/data/materialStock";
import { getError, getSuccess } from "@/core/helpers/utils";
export default defineComponent({
  name: "material-stock-form",
  components: {
    Datepicker,
  },
  setup() {
    const route = useRoute();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const tab2 = ref<any>(null);
    const file = ref<any>(null);
    const multiForm = ref<any>({});
    const fileError = ref<any>("");
    const po_id = ref<any>("");
    const purchase_order = ref<any>({});
    const selectedTab = ref<any>("Details");
    const isEdit = ref<boolean>(true);
    const isAdd = ref<boolean>(true);
    const universityList = ref<Array<any>>([]);
    const formData = ref(materialStockObjClone());
    const rules = ref({
      qty: [
        {
          required: true,
          message: "Type is required",
          trigger: "change",
        },
      ],
      stock_type: [
        {
          required: true,
          message: "Type is required",
          trigger: "change",
        },
      ],
    });
    const textAreaWordCount = ref<number>(0);
    onMounted(() => {
      multiForm.value = {
        multiple_mobile: [""],
        multiple_email: [""],
      };
      formData.value = materialStockObjClone();
      if (route.params.stockId) {
        isEdit.value = false;
        isAdd.value = false;
        getItemData();
      }
    });
    const setEdit = (status) => {
      isEdit.value = status;
    };
    const getItemData = () => {
      getMaterialStockById(route.params.id).then((MaterialItem: any) => {
        formData.value = {
          ...MaterialItem,
        };
        multiForm.value = {
          multiple_mobile: MaterialItem.multiple_mobile.split(","),
          multiple_email: MaterialItem.multiple_email.split(","),
        };
      });
    };

    const setSelectedTab = (name) => {
      selectedTab.value = name;
      getItemData();
    };
    const checkMaxSize = () => {
      fileError.value = "";
      if (file.value?.files[0]?.size > 1024 * 500) {
        fileError.value = "Max Size Should be 500kb";
      }
    };
    const materialId = computed(() => {
      return route.params.id;
    });
    const materialStockId = computed(() => {
      return route.params.stockId;
    });
    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          let reqObj: any = { ...formData.value };
          reqObj.material_id = materialId.value;
          addMaterialStock(reqObj, route.params.stockId)
            .then((responseData) => {
              loading.value = false;

              Swal.fire({
                text: getSuccess(
                  responseData,
                  "Form has been successfully submitted!"
                ),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                setTimeout(() => {
                  if (!route.params.stockId) {
                    router.push({
                      name: "apps-stock-material",
                      params: { id: materialId.value },
                    });
                  } else {
                    isEdit.value = false;
                    getItemData();
                  }
                }, 200);
              });
            })
            .catch((err) => {
              loading.value = false;
              Swal.fire({
                html: getError(err),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };
    const handleInput = (e) => {
      console.log("input");
    };
    const universityListComputed = computed(() => {
      return universityList.value.map((item) => ({
        ...item,
      }));
    });
    const userData = computed(() => {
      return store.getters.currentUser;
    });
    const isAdmin = computed(() => {
      return ["ADMIN"].indexOf(userData.value.role) > -1;
    });

    const onClickBack = () => {
      router.back();
    };

    const onInputTextAreaValidation = (event) => {
      textAreaWordCount.value = parseInt(event.target.value.length);
    };

    return {
      isEdit,
      setEdit,
      formData,
      rules,
      formRef,
      submit,
      loading,
      file,
      moment,
      isAdd,
      handleInput,
      universityListComputed,
      isAdmin,
      po_id,
      materialId,
      setSelectedTab,
      selectedTab,
      purchase_order,
      tab2,
      multiForm,
      checkMaxSize,
      fileError,
      onClickBack,
      textAreaWordCount,
      onInputTextAreaValidation,
    };
  },
});
