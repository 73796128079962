import moment from "moment";
import ApiService from "../services/ApiService";
interface MaterialStock {
  id: number;
  qty: string;
  remark: string;
  stock_type: string;
  material_id: string;
  material_data: string;
  created_by: string;
}

const CancelToken = ApiService.getInstance().CancelToken;
let cancelRequest;
const MaterialStocks: Array<MaterialStock> = [];

const deleteMaterialStock = function (item: MaterialStock) {
  return ApiService.delete(`material-stock/${item.id}/`);
};

const addMaterialStock = function (item, id) {
  delete item.created_by;
  if (id) {
    return ApiService.put(`material-stock/${id}/`, item);
  } else {
    return ApiService.post("material-stock/", item);
  }
};

const exportMaterialStockData = function (ids) {
  return new Promise((res, rej) => {
    ApiService.csvFile(
      "export/material-stock/?materialStock_id=" + ids,
      "materialStock-data"
    ).then((data) => res(data));
  });
};
const exportPDFMaterialStocks = function (ids) {
  return new Promise((res, rej) => {
    ApiService.pdfFile("pdf/material-stock/?materialStock_id=" + ids).then(
      (data) => res(data)
    );
  });
};
const materialStockObjClone = function () {
  return {
    id: 0,
    qty: "",
    remark: "",
    stock_type: "",
    material_id: "",
    material_data: "",
    created_by: "",
  };
};
const jsonToInterface = function (item: any) {
  const newItem: MaterialStock = materialStockObjClone();
  newItem.id = item.id;
  newItem.qty = item.qty;
  newItem.remark = item.remark;
  newItem.stock_type = item.stock_type;
  newItem.material_id = item.material_id;
  newItem.material_data = item.material_data;
  newItem.created_by = item.created_by;
  return newItem;
};
const getMaterialStockById = function (id) {
  return new Promise((res, rej) => {
    ApiService.get(`material-stock/${id}`).then(function (response) {
      res(jsonToInterface(response.data));
    });
  });
};
const getMaterialStockCountById = function (id) {
  return new Promise((res, rej) => {
    ApiService.getWithoutSlashNoCancel(`material-stock/${id}/count`).then(function (response) {
      res(response.data);
    });
  });
};
const getMaterialStocks = function (
  pageNumber = 1,
  searchData,
  status = "",
  material_id = ""
) {
  return new Promise((res, rej) => {
    const limit = 10;
    const queryObj = {
      page: pageNumber,
      limit: limit,
      offset: (pageNumber - 1) * limit,
      search: searchData.name || "",
      status: status,
      material_id: material_id,
    };
    console.log(queryObj);
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken.source();
    ApiService.getWithoutSlash(`material-stock/?${queryStr}`, cancelRequest)
      .then(function (response) {
        const resObj: any = {
          count: 0,
          data: [],
        };
        resObj.count = response.data.count;
        if (response.data && response.data.results) {
          resObj.data = response.data.results.map((item) => {
            return jsonToInterface(item);
          });
        }
        console.log("resObj", resObj);
        res(resObj);
      })
      .catch((error) => rej(error));
  });
};

const jsonToInterfaceMaterialStock = jsonToInterface;
const getEmptyObjOfMaterialStock = materialStockObjClone;
export {
  MaterialStock,
  getMaterialStocks,
  deleteMaterialStock,
  addMaterialStock,
  getMaterialStockById,
  jsonToInterfaceMaterialStock,
  materialStockObjClone,
  exportMaterialStockData,
  exportPDFMaterialStocks,
  getEmptyObjOfMaterialStock,
  getMaterialStockCountById,
};

export default MaterialStocks;
